var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "p-relative bg-image ratio " + (_vm.src ? "content-center" : ""),
      style: { paddingBottom: _vm.ratio && _vm.card ? _vm.padding + "%" : "" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        },
        blur: function($event) {
          return _vm.$emit("blur", $event)
        },
        focus: function($event) {
          return _vm.$emit("focus", $event)
        }
      }
    },
    [
      !_vm.src
        ? _c("div", { staticClass: "p-absolute fill-parent content-center" }, [
            _vm._m(0)
          ])
        : _vm._e(),
      _vm._v(" "),
      !this.playback && _vm.src && _vm.card
        ? _c(
            "div",
            {
              staticClass:
                "p-absolute fill-parent content-center z-9 overlay-dark overlay-70",
              style: { left: 0, top: 0 },
              on: {
                mouseenter: _vm.toggleOverlay,
                mouseleave: _vm.toggleOverlay
              }
            },
            [_vm._m(1)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.src && _vm.card
        ? _c(
            "video",
            {
              staticClass: "d-block",
              style: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                left: 0,
                top: 0
              },
              attrs: { loop: "", muted: "", src: _vm.src },
              domProps: { muted: true },
              on: {
                loadeddata: _vm.dataLoaded,
                mouseenter: _vm.startVideo,
                mouseleave: _vm.stopVideo
              }
            },
            [_vm._v("\n\t\tТег video не поддерживается вашим браузером.\n\t")]
          )
        : _vm.src && !_vm.card
        ? _c(
            "video",
            {
              staticClass: "d-block",
              attrs: { loop: "", controls: "", src: _vm.src },
              on: { loadeddata: _vm.dataLoaded }
            },
            [_vm._v("\n\t\tТег video не поддерживается вашим браузером.\n\t")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "sticker bg-light" }, [
      _c("i", { staticClass: "i-video" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "sticker bg-light" }, [
      _c("i", { staticClass: "i-play" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }